var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import RcTooltip from 'rc-tooltip';
import useMergedState from 'rc-util/lib/hooks/useMergedState';
import { useZIndex } from '../_util/hooks/useZIndex';
import { getTransitionName } from '../_util/motion';
import getPlacements from '../_util/placements';
import { cloneElement, isFragment } from '../_util/reactNode';
import { devUseWarning } from '../_util/warning';
import zIndexContext from '../_util/zindexContext';
import { ConfigContext } from '../config-provider';
import { NoCompactStyle } from '../space/Compact';
import { useToken } from '../theme/internal';
import PurePanel from './PurePanel';
import useStyle from './style';
import { parseColor } from './util';
const InternalTooltip = React.forwardRef((props, ref) => {
    var _a, _b;
    const { prefixCls: customizePrefixCls, openClassName, getTooltipContainer, overlayClassName, color, overlayInnerStyle, children, afterOpenChange, afterVisibleChange, destroyTooltipOnHide, arrow = true, title, overlay, builtinPlacements, arrowPointAtCenter = false, autoAdjustOverflow = true, } = props;
    const mergedShowArrow = !!arrow;
    const [, token] = useToken();
    const { getPopupContainer: getContextPopupContainer, getPrefixCls, direction, } = React.useContext(ConfigContext);
    // ============================== Ref ===============================
    const warning = devUseWarning('Tooltip');
    const tooltipRef = React.useRef(null);
    const forceAlign = () => {
        var _a;
        (_a = tooltipRef.current) === null || _a === void 0 ? void 0 : _a.forceAlign();
    };
    React.useImperativeHandle(ref, () => ({
        forceAlign,
        forcePopupAlign: () => {
            warning.deprecated(false, 'forcePopupAlign', 'forceAlign');
            forceAlign();
        },
    }));
    // ============================== Warn ==============================
    if (process.env.NODE_ENV !== 'production') {
        [
            ['visible', 'open'],
            ['defaultVisible', 'defaultOpen'],
            ['onVisibleChange', 'onOpenChange'],
            ['afterVisibleChange', 'afterOpenChange'],
            ['arrowPointAtCenter', 'arrow={{ pointAtCenter: true }}'],
        ].forEach(([deprecatedName, newName]) => {
            warning.deprecated(!(deprecatedName in props), deprecatedName, newName);
        });
        warning(!destroyTooltipOnHide || typeof destroyTooltipOnHide === 'boolean', 'usage', '`destroyTooltipOnHide` no need config `keepParent` anymore. Please use `boolean` value directly.');
        warning(!arrow || typeof arrow === 'boolean' || !('arrowPointAtCenter' in arrow), 'deprecated', '`arrowPointAtCenter` in `arrow` is deprecated. Please use `pointAtCenter` instead.');
    }
    // ============================== Open ==============================
    const [open, setOpen] = useMergedState(false, {
        value: (_a = props.open) !== null && _a !== void 0 ? _a : props.visible,
        defaultValue: (_b = props.defaultOpen) !== null && _b !== void 0 ? _b : props.defaultVisible,
    });
    const noTitle = !title && !overlay && title !== 0; // overlay for old version compatibility
    const onOpenChange = (vis) => {
        var _a, _b;
        setOpen(noTitle ? false : vis);
        if (!noTitle) {
            (_a = props.onOpenChange) === null || _a === void 0 ? void 0 : _a.call(props, vis);
            (_b = props.onVisibleChange) === null || _b === void 0 ? void 0 : _b.call(props, vis);
        }
    };
    const tooltipPlacements = React.useMemo(() => {
        var _a, _b;
        let mergedArrowPointAtCenter = arrowPointAtCenter;
        if (typeof arrow === 'object') {
            mergedArrowPointAtCenter =
                (_b = (_a = arrow.pointAtCenter) !== null && _a !== void 0 ? _a : arrow.arrowPointAtCenter) !== null && _b !== void 0 ? _b : arrowPointAtCenter;
        }
        return (builtinPlacements ||
            getPlacements({
                arrowPointAtCenter: mergedArrowPointAtCenter,
                autoAdjustOverflow,
                arrowWidth: mergedShowArrow ? token.sizePopupArrow : 0,
                borderRadius: token.borderRadius,
                offset: token.marginXXS,
                visibleFirst: true,
            }));
    }, [arrowPointAtCenter, arrow, builtinPlacements, token]);
    const memoOverlay = React.useMemo(() => {
        if (title === 0) {
            return title;
        }
        return overlay || title || '';
    }, [overlay, title]);
    const memoOverlayWrapper = (React.createElement(NoCompactStyle, null, typeof memoOverlay === 'function' ? memoOverlay() : memoOverlay));
    const { getPopupContainer, placement = 'top', mouseEnterDelay = 0.1, mouseLeaveDelay = 0.1, overlayStyle, rootClassName } = props, otherProps = __rest(props, ["getPopupContainer", "placement", "mouseEnterDelay", "mouseLeaveDelay", "overlayStyle", "rootClassName"]);
    const prefixCls = getPrefixCls('tooltip', customizePrefixCls);
    const rootPrefixCls = getPrefixCls();
    const injectFromPopover = props['data-popover-inject'];
    let tempOpen = open;
    // Hide tooltip when there is no title
    if (!('open' in props) && !('visible' in props) && noTitle) {
        tempOpen = false;
    }
    // ============================= Render =============================
    const child = React.isValidElement(children) && !isFragment(children) ? children : React.createElement("span", null, children);
    const childProps = child.props;
    const childCls = !childProps.className || typeof childProps.className === 'string'
        ? classNames(childProps.className, openClassName || `${prefixCls}-open`)
        : childProps.className;
    // Style
    const [wrapCSSVar, hashId, cssVarCls] = useStyle(prefixCls, !injectFromPopover);
    // Color
    const colorInfo = parseColor(prefixCls, color);
    const arrowContentStyle = colorInfo.arrowStyle;
    const formattedOverlayInnerStyle = Object.assign(Object.assign({}, overlayInnerStyle), colorInfo.overlayStyle);
    const customOverlayClassName = classNames(overlayClassName, {
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, colorInfo.className, rootClassName, hashId, cssVarCls);
    // ============================ zIndex ============================
    const [zIndex, contextZIndex] = useZIndex('Tooltip', otherProps.zIndex);
    const content = (React.createElement(RcTooltip, Object.assign({}, otherProps, { zIndex: zIndex, showArrow: mergedShowArrow, placement: placement, mouseEnterDelay: mouseEnterDelay, mouseLeaveDelay: mouseLeaveDelay, prefixCls: prefixCls, overlayClassName: customOverlayClassName, overlayStyle: Object.assign(Object.assign({}, arrowContentStyle), overlayStyle), getTooltipContainer: getPopupContainer || getTooltipContainer || getContextPopupContainer, ref: tooltipRef, builtinPlacements: tooltipPlacements, overlay: memoOverlayWrapper, visible: tempOpen, onVisibleChange: onOpenChange, afterVisibleChange: afterOpenChange !== null && afterOpenChange !== void 0 ? afterOpenChange : afterVisibleChange, overlayInnerStyle: formattedOverlayInnerStyle, arrowContent: React.createElement("span", { className: `${prefixCls}-arrow-content` }), motion: {
            motionName: getTransitionName(rootPrefixCls, 'zoom-big-fast', props.transitionName),
            motionDeadline: 1000,
        }, destroyTooltipOnHide: !!destroyTooltipOnHide }), tempOpen ? cloneElement(child, { className: childCls }) : child));
    return wrapCSSVar(React.createElement(zIndexContext.Provider, { value: contextZIndex }, content));
});
const Tooltip = InternalTooltip;
if (process.env.NODE_ENV !== 'production') {
    Tooltip.displayName = 'Tooltip';
}
Tooltip._InternalPanelDoNotUseOrYouWillBeFired = PurePanel;
export default Tooltip;
