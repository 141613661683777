import * as React from 'react';
import { useForm as useRcForm } from 'rc-field-form';
import scrollIntoView from 'scroll-into-view-if-needed';
import { getFieldId, toArray } from '../util';
function toNamePathStr(name) {
    const namePath = toArray(name);
    return namePath.join('_');
}
function getFieldDOMNode(name, wrapForm) {
    const field = wrapForm.getFieldInstance(name);
    if (field instanceof HTMLElement) {
        return field;
    }
    if ((field === null || field === void 0 ? void 0 : field.nativeElement) instanceof HTMLElement) {
        return field.nativeElement;
    }
    const fieldId = getFieldId(toArray(name), wrapForm.__INTERNAL__.name);
    if (fieldId) {
        return document.getElementById(fieldId);
    }
}
export default function useForm(form) {
    const [rcForm] = useRcForm();
    const itemsRef = React.useRef({});
    const wrapForm = React.useMemo(() => form !== null && form !== void 0 ? form : Object.assign(Object.assign({}, rcForm), { __INTERNAL__: {
            itemRef: (name) => (node) => {
                const namePathStr = toNamePathStr(name);
                if (node) {
                    itemsRef.current[namePathStr] = node;
                }
                else {
                    delete itemsRef.current[namePathStr];
                }
            },
        }, scrollToField: (name, options = {}) => {
            const node = getFieldDOMNode(name, wrapForm);
            if (node) {
                scrollIntoView(node, Object.assign({ scrollMode: 'if-needed', block: 'nearest' }, options));
            }
        }, getFieldInstance: (name) => {
            const namePathStr = toNamePathStr(name);
            return itemsRef.current[namePathStr];
        } }), [form, rcForm]);
    return [wrapForm];
}
